import store from '@/state/store'

export default [
  {
    path: "/",
    name: "inicio",
    component: () => import("../layouts/body.vue"),
  },
  {
    path: "/detalle-paquete/:id",
    name: "detallePaquete",
    component: () => import("../layouts/package-detail/detail.vue"),
    meta: {
      authRequired: true,
    },
    // beforeEnter: (to, from, next) => {
    //   return checkUserActivation(to, from, next);
    // },
  },
  {
    path: "/compra-exitosa",
    name: "buy success",
    component: () => import("../layouts/package-detail/buy.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    
    path: "/configuration",
    meta: {
      authRequired: true,
    },
    name: "configuration",
    component: () => import("@/components/wizzard/index.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    //component: () => import("./../components/dashboard/index.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      const data = localStorage.getItem("user");
      const user = JSON.parse(data);
      const configured = localStorage.getItem("configured");
      if(configured == 0 || configured == false){
        return next({ name: "configuration" });
      }
      
      if (user.is_active == 0) {
        next({ name: "DeactivateUser" });
      } else {
        if (user.role == "Administrator") {
          // Redirigir al componente de administrador
          next({ name: "DashboardAdmin" });
        } else if (user.role === "Client") {
          // Redirigir al componente de usuario
          next({ name: "DashboardCustomer" });
        }
      }
    },
  },
  {
    path: "/deactivate-user",
    name: "DeactivateUser",
    component: () => import("../layouts/deactivate.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/dashboard",
    name: "DashboardCustomer",
    component: () => import("./../components/dashboard/index.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/dashboard",
    name: "DashboardAdmin",
    component: () => import("./../components/dashboardAdmin/index.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/auth/login.vue"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem("user");
        if (loggeduser) {
          // Redirect to the home page instead
          next({
            name: "Dashboard",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem("user");
        if (loggeduser) {
          // Redirect to the home page instead
          next({
            name: "Dashboard",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/components/auth/forgot-password.vue"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem("user");
        if (loggeduser) {
          // Redirect to the home page instead
          next({
            name: "Dashboard",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: "default",
              }
            : {
                ...routeFrom,
              }
        );
      },
    },
  },

  {
    path: "/facturacion/nueva/:type",
    name: "invoice",
    component: () => import("../components/invoicing/invoice_new.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/factura/detalle/:id",
    name: "detailInvoice",
    component: () =>
      import("./../components/invoicing/sections/detail_invoice.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/facturacion/pendientes",
    name: "pendingInvoices",
    component: () => import("./../components/invoicing/pending_invoices.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/facturacion/timbradas",
    name: "stampedInvoices",
    component: () => import("./../components/invoicing/stamped_invoices.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/facturacion/canceladas",
    name: "cancelledInvoices",
    component: () => import("./../components/invoicing/cancelled_invoices.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/facturacion/clientes",
    name: "clients",
    component: () => import("../components/clients/client.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/facturacion/clientes/nuevo",
    name: "newClient",
    component: () => import("../components/clients/client_new.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/facturacion/clientes/editar/:id",
    name: "editClient",
    component: () => import("../components/clients/client_edit.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/facturacion/configuracion/empresa",
    name: "companyConfiguration",
    component: () => import("./../components/invoicing/company.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/facturacion/configuracion/serie-folio",
    name: "companyConfigurationSeriesfolio",
    component: () => import("./../components/invoicing/series_folios.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  
  {
    path: "/contabilidad/facturas-recibidas",
    name: "facturas recibidas",
    component: () => import("../components/accounting/invoicesPending.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/contabilidad/complementos-de-pago",
    name: "complementos de pago",
    component: () => import("../components/accounting/paymentComplement.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/contabilidad/conciliacion",
    name: "conciliation",
    component: () => import("../components/accounting/conciliation.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/contabilidad/pagos-isr",
    name: "paymentsISR",
    component: () => import("../components/accounting/payments_isr.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/my-account",
    name: "myAccount",
    component: () => import("../components/profile/my_account.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte",
    name: "billLading",
    component: () => import("../components/billLading/bill_lading.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte-editar/:type",
    name: "billLadingEdit",
    component: () => import("../components/billLading/bill_lading_edit.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/timbradas",
    name: "billLadingStamped",
    component: () =>
      import("./../components/billLading/bill_lading_stamped.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/operadores",
    name: "operators",
    component: () => import("../components/billLading/operator.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/operadores/nuevo",
    name: "operatorsNew",
    component: () => import("../components/billLading/operator_new.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/operadores/editar/:id",
    name: "operatorsEdit",
    component: () => import("../components/billLading/operator_edit.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/unidades",
    name: "units",
    component: () => import("../components/billLading/unit.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/unidades/nueva",
    name: "unitsNew",
    component: () => import("../components/billLading/unit_new.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/unidades/editar/:id",
    name: "unitsEdit",
    component: () => import("../components/billLading/unit_edit.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/ubicaciones",
    name: "ubications",
    component: () => import("../components/billLading/ubication.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/ubicaciones/nueva",
    name: "ubicationsNew",
    component: () => import("../components/billLading/ubication_new.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/carta-porte/ubicaciones/editar/:id",
    name: "ubicationsEdit",
    component: () => import("../components/billLading/ubication_edit.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  // RUTAS PANEL DOS
  {
    path: "/clientes",
    name: "clients",
    component: () => import("../components/clients/client.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/clientes/nuevo",
    name: "clientsNew",
    component: () => import("../components/clients/client_new.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/legal/terminos-condiciones",
    name: "termConditions",
    component: () => import("../components/legal/terms_conditions.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/legal/politicas-privacidad",
    name: "politicasPrivacidad",
    component: () => import("../components/legal/privacy_policies.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/preguntas-frecuentes",
    name: "faq",
    component: () => import("../components/faq/faq.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/preguntas-frecuentes/nueva",
    name: "faqNew",
    component: () => import("../components/faq/faq_new.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/preguntas-frecuentes/editar/:id",
    name: "faqEdit",
    component: () => import("../components/faq/faq_edit.vue"),
    meta: {
      authRequired: true,
    },
  },

  // Paquetes
  {
    path: "/paquetes",
    name: "paquetes",
    component: () => import("../components/package/admin/index.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/paquetes/nuevo",
    name: "paquetesNuevo",
    component: () => import("../components/package/admin/new.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/paquetes/edit/:id",
    name: "paquetesEdit",
    component: () => import("../components/package/admin/new.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/beneficios",
    name: "beneficios",
    component: () => import("../components/package/admin/benefits/index.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/beneficios/:id",
    name: "beneficiosEdit",
    component: () => import("../components/package/admin/benefits/edit.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/crear-complemento-de-pago",
    name: "ComplementoDePago",
    component: () => import("../components/payment_complement/payment.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/complemento-de-pago/nuevo/:id",
    name: "newComplementoDePago",
    component: () => import("../components/payment_complement/new_payment.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/complemento-de-pago-pendientes",
    name: "PendingComplementoDePago",
    component: () =>
      import("../components/payment_complement/pending_payment.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/complemento-de-pago-timbradas",
    name: "StampedComplementoDePago",
    component: () =>
      import("../components/payment_complement/stamped_payment.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/complemento-de-pago-canceladas",
    name: "cancelComplementoDePago",
    component: () =>
      import("../components/payment_complement/cancel_payment.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/complemento-de-pago/bancos",
    name: "banks",
    component: () => import("../components/payment_complement/bank/bank.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/complemento-de-pago/banco/nuevo",
    name: "newBank",
    component: () =>
      import("../components/payment_complement/bank/bank_new.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/complemento-de-pago/banco/editar/:id",
    name: "editBank",
    component: () =>
      import("../components/payment_complement/bank/bank_edit.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  // Cuentas
  {
    path: "/cuentas/pagar",
    name: "cuentaspagar",
    component: () => import("../components/accounts/payable/index.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/cuentas/pagar/index",
    name: "cuentaspagarlist",
    component: () => import("../components/accounts/payable/list.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/cuentas/cobrar",
    name: "cuentascobrar",
    component: () => import("../components/accounts/receivable/index.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/cuentas/cobrar/:cliente_id",
    name: "cuentascobrarstatement",
    component: () => import("../components/accounts/receivable/statement.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/cuentas/cobrar/historico/:id",
    name: "cuentascobrarstatementhistorical",
    component: () => import("../components/accounts/receivable/historical.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/cuentas/cobrar/aplicar_pago/:id",
    name: "cuentascobrarstatementpay",
    component: () => import("../components/accounts/receivable/pay.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/cuentas/cobrar/aplicar_pago/multiple",
    name: "cuentascobrarstatementpaymultiple",
    component: () =>
      import("../components/accounts/receivable/payMultiple.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/calcular/index",
    name: "CalcularPagoIsr",
    component: () => import("../components/calculation/index.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/banca",
    name: "paybook",
    component: () => import("../components/paybook/index.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/banca-widget",
    name: "paybookWidget",
    component: () => import("../components/paybook/widget.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },

  //Banners inicio
  {
    path: "/secciones",
    name: "secciones",
    component: () => import("../components/banners/front-page/secciones/secciones.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/secciones/:tipo",
    name: "seccionesTipo",
    component: () => import("../components/banners/front-page/secciones/index.vue"),
    meta: {
      authRequired: true,
    },
  },
  
  {
    path: "/secciones/nuevo/:tipo",
    name: "seccionesNew",
    component: () => import("../components/banners/front-page/secciones/new.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/secciones/editar/:id",
    name: "seccionesEdit",
    component: () => import("../components/banners/front-page/secciones/edit.vue"),
    meta: {
      authRequired: true,
    },
  },
  
  {
    path: "/banner-portada",
    name: "BannerPortada",
    component: () => import("../components/banners/front-page/index.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/banner-portada-nuevo",
    name: "BannerPortadaNew",
    component: () => import("../components/banners/front-page/new.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/banner-portada-editar/:id",
    name: "BannerPortadaEdit",
    component: () => import("../components/banners/front-page/edit.vue"),
    meta: {
      authRequired: true,
    },
  },
  //Banners clientes
  {
    path: "/banner-cliente",
    name: "BannerCliente",
    component: () => import("../components/banners/customers/index.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/banner-cliente-nuevo",
    name: "BannerClienteNew",
    component: () => import("../components/banners/customers/new.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/banner-cliente-editar/:id",
    name: "BannerClienteEdit",
    component: () => import("../components/banners/customers/edit.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mensaje-contacto/nuevo",
    name: "ContactMessageNew",
    component: () => import("../components/contact_messages/new.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mensaje-contacto",
    name: "ContactMessage",
    component: () => import("../components/contact_messages/index.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mensaje-contacto/informacion/:id",
    name: "ContactMessageInfo",
    component: () => import("../components/contact_messages/info.vue"),
    meta: {
      authRequired: true,
    },
  },

  //conciliación
  {
    path: "/conciliacion-facturas-emitidas",
    name: "ConciliacionIndex",
    component: () => import("../components/conciliation/index.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/conciliacion/registrar-pago/:id",
    name: "ConciliacionRegisterPay",
    component: () => import("../components/conciliation/register_pay.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/conciliacion/historico-pago/:id",
    name: "ConciliacionHistoricPay",
    component: () => import("../components/conciliation/history_pay.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  //conciliación manual
  {
    path: "/conciliacion-manual",
    name: "ConciliacionIndexManual",
    component: () => import("../components/conciliation/index-manual.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  //conciliación manual
  {
    path: "/conciliacion-bancos",
    name: "ConciliacionBanckIndex",
    component: () => import("../components/conciliation/bank/index.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  // Conciliación por facturas recibidadas
  {
    path: "/conciliacion-facturas-recibidas",
    name: "ConciliacionRecibidasIndex",
    component: () =>
      import("../components/conciliation/invoices_received/index.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },

  {
    path: "/conciliacion-facturas-recibidas/registrar-pago/:id",
    name: "ConciliacionRecibidasRegisterPay",
    component: () =>
      import("../components/conciliation/invoices_received/register_pay.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/conciliacion-facturas-recibidas/historico-pago/:id",
    name: "ConciliacionRecibidasHistoricPay",
    component: () =>
      import("../components/conciliation/invoices_received/history_pay.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  {
    path: "/conciliacion-lista-transacciones/:id",
    name: "ConciliacionManualListTransaction",
    component: () =>
      import("../components/conciliation/sections/bank-transaction.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
  //Deducciones
  {
    path: "/deducciones/index",
    name: "DeduccionesIndex",
    component: () => import("../components/deduction/index.vue"),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      return checkUserActivation(to, from, next);
    },
  },
];

function checkUserActivation(to, from, next) {
  const data = localStorage.getItem("user");
  const user = JSON.parse(data);
  console.log();
  if (user.is_active == 0) {
    next({ name: "DeactivateUser" });
  } else {
    next();
  }
}