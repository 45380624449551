
export const helpers_todo = {
  count_caracteres,
  date_format,
};

function count_caracteres(event, count) {
    if (event.length > count) {
      return false;
    } else {
      return true;
    }
}

function date_format(date) {

  var fecha = new Date(date);

  var dia = fecha.getDate().toString().padStart(2, "0");
  var mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
  var anio = fecha.getFullYear();
  var horas = fecha.getHours().toString().padStart(2, "0");
  var minutos = fecha.getMinutes().toString().padStart(2, "0");
  var segundos = fecha.getSeconds().toString().padStart(2, "0");


  var fechaFormateada = `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos}`;

  return fechaFormateada;
}



