const configuredStorage = localStorage.getItem('configured')
console.log(!configuredStorage);
export const state = {
    layoutType: 'horizontal',
    layoutWidth: 'fluid',
    leftSidebarType: 'dark',
    topbar: 'dark',
    configured :JSON.parse(configuredStorage) || 0
}

export const getters = {}

export const mutations = {
    CHANGE_LAYOUT(state, layoutType) {
        state.layoutType = layoutType;
    },
    CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
        state.layoutWidth = layoutWidth;
    },
    CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
        state.leftSidebarType = leftSidebarType;
    },
    CHANGE_TOPBAR(state, topbar) {
        state.topbar = topbar;
    },
    UPDATE_CONFIGURED(state, configured) {
        state.configured = configured;
        localStorage.setItem('configured',state.configured);
    }
}

export const actions = {
    changeLayoutType({ commit }, { layoutType }) {
        commit('CHANGE_LAYOUT', layoutType);
    },

    changeLayoutWidth({ commit }, { layoutWidth }) {
        commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
    },

    changeLeftSidebarType({ commit }, { leftSidebarType }) {
        commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType)
    },

    changeTopbar({ commit }, { topbar }) {
        commit('CHANGE_TOPBAR', topbar)
    },

    updateConfiguredAdmin({ commit }, { configured }) {
        console.log("si lo configura",{ configured });
        var conf = { configured }
        console.log(conf);
        
        commit('UPDATE_CONFIGURED', configured)
    }
}
