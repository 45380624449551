
const userStorage = JSON.parse(localStorage.getItem('user'));
export const state = {
  user: userStorage || null,
  role: (userStorage) ? userStorage.role : null
}

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
    console.log(state.user);
  },

  SET_ROLE(state, role) {
    state.role = role;
  },
}

export const getters = {
  loggedIn(state) {
    return !!state.user
  },
}

export const actions = {
  currentUser({ commit }, { user }) {
    console.log("si esta llamando esta accion");
    commit('SET_USER', user);
  },
  roleUser({ commit }, { role }) {
    console.log("rol",role);
    commit('SET_ROLE', role);
  },
}

