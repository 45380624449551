import axios from 'axios';
export const state = {
    regimen:[],
    regimen_wizzard:[],
    estados:[],
    cfdi:[],
    wayToPay:[],
    paymentMethod:[],
    voucherType:[],
    exportType:[],
    currency:[],
    products:[],
    units:[],
    taxesObject:[],
    taxes:[],
    factorType:[],
    permisoSat:[],
    configVehicular:[],
    subRemolque:[],
    typeDocument:[],
    paises:[],
    relationsShip:[],
    incoterm:[],
    clavePedimento:[],
    tipoEstacion:[],
    tipoUbicacion:[],
    bankPaybook:[],
    accountTypePaybook:[],
}

export const getters = {}

export const mutations = {
  setTaxRegimen(state, regimen) {
    state.regimen = regimen;
  },
  setTaxRegimen_wizzard(state, regimen_wizzard) {
    state.regimen_wizzard = regimen_wizzard;
  },
  setEstados(state, estados) {
    state.estados = estados;
  },
  setUsoCfdi(state, cfdi) {
    state.cfdi = cfdi;
  },
  setWayToPay(state, wayToPay) {
    state.wayToPay = wayToPay;
  },
  setPaymentMethod(state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  },
  setVoucherType(state, voucherType) {
    state.voucherType = voucherType;
  },
  setExportType(state, exportType) {
    state.exportType = exportType;
  },
  setCurrency(state, currency) {
    state.currency = currency;
  },
  setProduct(state, products) {
    state.products = products;
  },
  setUnit(state, units) {
    state.units = units;
  },
  setTaxesObject(state, taxesObject) {
    state.taxesObject = taxesObject;
  },
  setTaxes(state, taxes) {
    state.taxes = taxes;
  },
  setFactorType(state, factorType) {
    state.factorType = factorType;
  },
  setPermisoSat(state, permisoSat) {
    state.permisoSat = permisoSat;
  },
  setConfigVehicular(state, configVehicular) {
    state.configVehicular = configVehicular;
  },
  setSubRemolque(state, subRemolque) {
    state.subRemolque = subRemolque;
  },
  setTypeDocument(state, typeDocument) {
    state.typeDocument = typeDocument;
  },
  setPaises(state, paises) {
    state.paises = paises;
  },
  setTypeRelationsShip(state, relationsShip) {
    state.relationsShip = relationsShip;
  },
  setIncoterm(state, incoterm) {
    state.incoterm = incoterm;
  },
  setClavePedimento(state, clavePedimento) {
    state.clavePedimento = clavePedimento;
  },
  setTipoEstacion(state, tipoEstacion) {
    state.tipoEstacion = tipoEstacion;
  },
  setTipoUbicacion(state, tipoUbicacion) {
    state.tipoUbicacion = tipoUbicacion;
  },
  setBankPaybook(state, bankPaybook) {
    state.bankPaybook = bankPaybook;
  },
  setAccountTypePaybook(state, accountTypePaybook) {
    state.accountTypePaybook = accountTypePaybook;
  },
};

export const actions = {
  async getEstados({ commit }) {
    await axios
      .get("/catalogues/estados")
      .then((response) => {
        let data = response.data.data.estados;
        commit("setEstados", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getTaxRegimen({ commit }) {
    await axios
      .get("/catalogues/regimen")
      .then((response) => {
        let data = response.data.data.regimen;
        commit("setTaxRegimen", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getTaxRegimenWizzard({ commit }) {
    await axios
      .get("/catalogues/regimen_wizzard")
      .then((response) => {
        let data = response.data.data.regimen;
        commit("setTaxRegimen_wizzard", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getCfdi({ commit }) {
    await axios
      .get("/catalogues/cfdi")
      .then((response) => {
        let data = response.data.data.cfdi;
        commit("setUsoCfdi", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getWayToPay({ commit }) {
    axios
      .get("/catalogues/way_to_pay")
      .then((response) => {
        let data = response.data.data.wayToPay;
        commit("setWayToPay", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getPaymentMethod({ commit }) {
    await axios
      .get("/catalogues/payment_method")
      .then((response) => {
        let data = response.data.data.paymentMethod;
        commit("setPaymentMethod", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getVoucher({ commit }) {
    await axios
      .get("/catalogues/voucher")
      .then((response) => {
        let data = response.data.data.voucher;
        commit("setVoucherType", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getExportType({ commit }) {
    await axios
      .get("/catalogues/export_type")
      .then((response) => {
        let data = response.data.data.export;
        commit("setExportType", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getCurrency({ commit }) {
    await axios
      .get("/catalogues/currency")
      .then((response) => {
        let data = response.data.data.currency;
        commit("setCurrency", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getProduct({ commit }) {
    await axios
      .get("/catalogues/product")
      .then((response) => {
        let data = response.data.data.product;
        commit("setProduct", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getUnit({ commit }) {
    await axios
      .get("/catalogues/unit")
      .then((response) => {
        let data = response.data.data.unit;
        commit("setUnit", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getTaxesObject({ commit }) {
    await axios
      .get("/catalogues/tax_object")
      .then((response) => {
        let data = response.data.data.tax_object;
        commit("setTaxesObject", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getTaxes({ commit }) {
    await axios
      .get("/catalogues/tax")
      .then((response) => {
        let data = response.data.data.tax;
        commit("setTaxes", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getFactorType({ commit }) {
    await axios
      .get("/catalogues/factor_type")
      .then((response) => {
        let data = response.data.data.factor_type;
        commit("setFactorType", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getPermisoSat({ commit }) {
    await axios
      .get("/catalogues/permiso_sat")
      .then((response) => {
        let data = response.data.data;
        commit("setPermisoSat", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getConfigVehicular({ commit }) {
    await axios
      .get("/catalogues/config_vehicular")
      .then((response) => {
        let data = response.data.data;
        commit("setConfigVehicular", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getSubRemolque({ commit }) {
    await axios
      .get("/catalogues/tipo_sub_remolque")
      .then((response) => {
        let data = response.data.data;
        commit("setSubRemolque", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getTypeDocument({ commit }) {
    await axios
      .get("/catalogues/type_document")
      .then((response) => {
        let data = response.data.data;
        commit("setTypeDocument", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getPaises({ commit }) {
    await axios
      .get("/catalogues/pais")
      .then((response) => {
        let data = response.data.data;
        commit("setPaises", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getTypeRelationShip({ commit }) {
    await axios
      .get("/catalogues/relationship_type")
      .then((response) => {
        let data = response.data.data.relationship;
        commit("setTypeRelationsShip", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getIncoterm({ commit }) {
    await axios
      .get("/catalogues/incoterm")
      .then((response) => {
        let data = response.data.data.incoterm;
        commit("setIncoterm", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getClavePedimento({ commit }) {
    await axios
      .get("/catalogues/clavePedimento")
      .then((response) => {
        let data = response.data.data.clavePedimento;
        commit("setClavePedimento", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getTypeStation({ commit }) {
    await axios
      .get("/catalogues/tipoEstacion")
      .then((response) => {
        let data = response.data.data;
        commit("setTipoEstacion", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getTypeUbication({ commit }) {
    await axios
      .get("/catalogues/tipo_ubicacion")
      .then((response) => {
        let data = response.data.data;
        commit("setTipoUbicacion", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getBankPaybook({ commit }) {
    await axios
      .get("/catalogues/catBankPaybook")
      .then((response) => {
        let data = response.data.data.catBankPaybook;
        commit("setBankPaybook", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getAccountTypePaybook({ commit }) {
    await axios
      .get("/catalogues/catAccountsPaybook")
      .then((response) => {
        let data = response.data.data.accountTypes;
        commit("setAccountTypePaybook", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};