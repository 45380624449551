import Swal from "sweetalert2";

export const responseApi = {
    error(error) {
      let response = error.response.data
        var error_='';
        var code = ( error.response.status == 500) ? 'error':'warning'
        if(response.data && Object.prototype.hasOwnProperty.call(response.data, 'errors')){
            if(typeof(response.data.errors)== 'object' ){
                
                for (var key in response.data.errors) {
                    if (Object.hasOwnProperty.call(response.data.errors, key)) {
                      var errorValue = response.data.errors[key];
                      error_ += '* ' + errorValue + '<br>';
                    }
                  }
                
            }
        }else{
            error_ = response.message
        }
        Swal.fire({
            title: (error.response.status == 500)?'Advertencia':'',
            html: error_,
            icon: code,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            })
      },

      
      warning() {
        console.log("Esta es una función global que puedes usar en tus componentes");
      }
};
