import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import axios from 'axios';
import Pagination from 'vue-pagination-2';
// Vue.component('paginateTool', Paginate);
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

axios.defaults.baseURL = process.env.VUE_APP_api

axios.interceptors.request.use(config => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    config.headers.Authorization = `Bearer ${user.token}`;
  }
  return config;
});

import "@/assets/scss/index.scss";
import $ from 'jquery';

import { responseApi } from './helpers/response_api';

import { helpers_todo } from "./helpers/helper_todo";

// Registra la función como un complemento de Vue
Vue.use({
  install(Vue) {
    Vue.prototype.$responseApi = responseApi;
  },
});
Vue.use({
  install(Vue) {
    Vue.prototype.$helperTodo = helpers_todo;
  },
});

Vue.use({
  install (V) {
    V.$ = V.prototype.$ = $
  }
})

import Sparkline from 'vue-sparklines'

// import { initFirebaseBackend } from './authUtils'
// import { configureFakeBackend } from './helpers/fake-backend';

import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import es from 'vue2-datepicker/locale/es';



// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_apiKey,
//   authDomain: process.env.VUE_APP_authDomain,
//   databaseURL: process.env.VUE_APP_databaseURL,
//   projectId: process.env.VUE_APP_projectId,
//   storageBucket: process.env.VUE_APP_storageBucket,
//   messagingSenderId: process.env.VUE_APP_messagingSenderId,
//   appId: process.env.VUE_APP_appId,
//   measurementId: process.env.VUE_APP_measurementId
// };

// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//   initFirebaseBackend(firebaseConfig);
// } else {
//   configureFakeBackend();
// }

Vue.use(BootstrapVue);

Vue.config.productionTip = true

Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.use(Sparkline)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(VueFormWizard)
Vue.component('apexChart', VueApexCharts)
// Vue.component('paginateTool', Paginate);
Vue.component('paginationTool', Pagination);
Vue.prototype.$datepickerLang = es;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
